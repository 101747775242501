import React from "react";
import Layout from "../../components/layout";
import Accordion from "starkeydigital/React/Components/accordion";
interface props {}
const CaseStudies: React.FC<props> = (props) => {
  return (
    <Layout
      titleText="Case Studies"
      titleText2=""
      slogan="We provide a number of case studies giving examples of the coaching work we do."
    >
      <div style={{marginTop: "115px"}}>
        <Accordion>
          <Accordion.Item title="Helping new partners in their transition to leadership roles within a firm.">
            <p style={{marginTop: "35px"}}>
              <strong>Purpose:</strong> To ensure that new partners are equipped
              with the support and challenge needed to make this key transition.
            </p>

            <p style={{margin: "35px 0"}}>
              <strong>Experience and scope:</strong> Often a programme of six
              90-minute sessions, using the new partner’s aims and the firm’s
              strategy to form the agenda. Working on the opportunities and
              challenges the partner is facing in ‘real time’. Programmes have
              been undertaken with new partners in Magic Circle law firms, ‘big
              four’ accounting firms and other professional service firms
              internationally.
            </p>

            <p>
              <strong> Themes: </strong>
              The following themes have emerged as recommended topics on the
              coaching programme:
            </p>

            <ul className="dashed" style={{marginBottom: "115px"}}>
              <li>
                External support and challenge as the infrastructure of the
                partnership process falls away. This is a point in their careers
                when partners often feel very alone and yet reluctant to reach
                out to people internally as they feel they should be able to do
                what they planned without support.
              </li>
              <li>
                Practical support and challenge as the business case for
                partnership turns from an aspirational plan into practical
                action.
              </li>
              <li>
                Support as their role within the team changes to one of
                leadership and new alliances are made within the partnership.
              </li>
              <li>Work on confidence and imposter syndrome.</li>
              <li>
                An external view as they learn to navigate the politics within
                the partnership.
              </li>
              <li>Working smarter not harder, and resilience.</li>
            </ul>
          </Accordion.Item>

          <Accordion.Item title="Working with high potential senior associates to help them achieve their potential which is often to progress towards partnership.">
            {/* <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col"
        header="Working with high potential senior associates to help them achieve their potential which is often to progress towards partnership."
      /> */}

            <p style={{marginTop: "35px"}}>
              <strong>Purpose:</strong> Ensuring the coachee is achieving their
              potential and (where relevant) presenting themselves as the best
              candidate for partnership that they can be so that the firm is
              able to see the full potential of its emerging talent.
            </p>

            <p style={{margin: "35px 0"}}>
              <strong>Experience and scope:</strong> Often a programme of six
              90-minute coaching sessions with the first and last sessions
              working with the coachee and their sponsor to ensure that this
              relationship is working alongside the coaching. Our experience is
              wide ranging across Magic Circle law firms, the ‘big four’
              accounting firms and many other professional service firms
              internationally.
            </p>

            <p>
              <strong> Themes: </strong>
              The following themes have emerged as recommended topics on the
              coaching programme:
            </p>
            <ul className="dashed" style={{marginBottom: "115px"}}>
              <li>
                Ensuring that the coachee has an effective and engaged
                development network of sponsors and mentors around them and that
                they are using role models effectively.
              </li>
              <li>
                Ensuring that the coachee has their ‘head up’ and is thinking
                commercially about the work that they are doing and the
                opportunities in the market so that they are creating a business
                case showing what they could add to the partnership.
              </li>
              <li>
                Support as their role within the team changes to one of
                leadership and new alliances are made within the partnership.
              </li>
              <li>
                Working with the coachee and their sponsor to ensure that they
                are working on the matters that will give them the experience so
                that they can take advantage of the opportunities in the market.
              </li>
              <li>
                Working with the coachee to ensure that they are building a team
                around them so that they can step up to a more senior role and
                realise their potential at this level.
              </li>
              <li>
                Ensuring the coachee is building the network of partners that
                they will need to support them in their ambitions and in their
                future as a leader of the business.
              </li>
              <li>Working smarter not harder, and resilience.</li>
            </ul>
          </Accordion.Item>

          <Accordion.Item title="Working with senior female employees to help improve retention and support career progression.">
            {/* <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col"
        header="Working with senior female employees to help improve retention and support career progression."
      /> */}

            <p style={{marginTop: "35px"}}>
              <strong>Purpose:</strong>To provide support to female Senior
              Managers, Senior Associates, Counsel and Directors as they
              progress towards partnership working on key challenges at both
              personal and organisational levels. Overall goal to improve
              retention and increase the number of female partners.
            </p>

            <p style={{margin: "35px 0"}}>
              <strong>Experience and scope:</strong> Coaching provided annually
              to 150-200 senior women across the EMEIA region. Up to three
              sessions of 90 minutes each. Annual themes report (anonymous)
              provided to client. (Programme in its third year).
            </p>

            <p>
              <strong> Themes: </strong>
              The following themes have emerged as recommended topics on the
              coaching programme:
            </p>

            <ul className="dashed" style={{marginBottom: "115px"}}>
              <li>
                Maintaining a focus on driving their career (particularly during
                the pandemic).
              </li>
              <li>The difficulty of networking virtually.</li>
              <li>Mental health and wellbeing.</li>
              <li>
                To become effective partners - helping the women shift from task
                orientation to taking on the role of leader.
              </li>
              <li>Ensuring the sponsor role is effective.</li>
              <li>
                Providing support to help development in a male environment.
              </li>
              <li>Helping women develop their business.</li>
              <li>
                Helping women make informed decisions when deciding whether or
                not to leave the firm.
              </li>
            </ul>
          </Accordion.Item>

          <Accordion.Item title="Working with new graduates to help them focus their efforts early in their careers.">
            {/* <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col"
        header="Working with new graduates to help them focus their efforts early in their careers."
      /> */}

            <p style={{marginTop: "35px"}}>
              <strong>Purpose:</strong> To establish helpful habits in the early
              years of a professional’s career so that they are able to achieve
              their potential throughout their career.
            </p>

            <p style={{margin: "35px 0"}}>
              <strong>Experience and scope:</strong> The feedback from this work
              indicates that it has made a huge difference to new graduates,
              particularly women, those from a minority ethnic background and
              those whose families do not bring experience of working in a
              professional service firm. In addition to our extensive work with
              women in professional service firms we have run programmes for
              those from a minority ethnic and social movement population for
              two magic circle law firms.
            </p>
            <p>
              <strong> Themes: </strong>
              The following themes have emerged as recommended topics on the
              coaching programme:
            </p>

            <ul className="dashed" style={{marginBottom: "115px"}}>
              <li>
                Ensuring that they understand the power of an engaged and active
                development network, including mentors, sponsors and role models
                and how to identify them.
              </li>
              <li>
                Helping them engage and grow their existing network within the
                firm and outside it.
              </li>
              <li>
                Working to improve their confidence and combat the imposter
                syndrome.
              </li>
              <li>
                Helping them manage the impression they give to others so that
                those others are aware of all that they bring to the firm.
              </li>
              <li>
                Working on time management so that good habits are there from
                the start and there is less risk of burnout.
              </li>
              <li>Working smarter not harder, and resilience.</li>
            </ul>
          </Accordion.Item>

          <Accordion.Item title="Providing focused support to established partners to help them move towards senior leadership roles.">
            {/* <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col"
        header="Providing focused support to established partners to help them move towards senior leadership roles."
      /> */}

            <p style={{marginTop: "35px"}}>
              <strong>Purpose:</strong> To ensure that firms are aware of the
              broadest range of talent when leadership appointments are made.
            </p>

            <p style={{margin: "35px 0"}}>
              <strong>Experience and scope:</strong> Working with partners to
              think more widely about the leadership roles that are available in
              the firm and how they might take up one of these roles. Often a
              programme of six 90-minute sessions flexed around the coachee’s
              key events to give them support and challenge when needed.
            </p>
            <p>
              <strong> Themes: </strong>
              The following themes have emerged as recommended topics on the
              coaching programme:
            </p>

            <ul className="dashed" style={{marginBottom: "115px"}}>
              <li>Raising awareness of the leadership roles available.</li>
              <li>
                Raising awareness of the politics within the partnership and
                what the levers are to achieve success in a leadership role in a
                partnership.
              </li>
              <li>
                Working to improve their confidence and combat the imposter
                syndrome.
              </li>
              <li>
                Creating a practical strategy to achieve that vision and which
                supports the firms wider aims and culture.
              </li>
              <li>
                Working on the communication that will be needed to bring their
                fellow partners with them and gain real buy-in.
              </li>
              <li>
                Dealing with difficult relationships within the team or outside
                it.
              </li>

              <li>Working smarter not harder, and resilience.</li>
            </ul>
          </Accordion.Item>
        </Accordion>

        {/* <RandomQuote /> */}
        {/* <BookShort /> */}
      </div>
    </Layout>
  );
};
export default CaseStudies;
